import { useTranslation } from "react-i18next"
import styled, { useTheme } from "styled-components"
import CreateTechRow from "../organisms/techPack/CreateTechRow"
import GeneralInfo from "../organisms/techPack/GeneralInfo"
import Header from "../organisms/techPack/Header"
import mediaQuery from "../../util/mediaQuery"
import TechPackContextProvider from "../../context/techPack"
import useTechPack from "../../hooks/useTechPack"
import TechPackModal from "../organisms/techPack/TechPackModal"
import ContactSection from "../organisms/techPack/ContactSection"
import {
	PRODUCTION_STAGING_KEYS,
	PRODUCTION_LOADING_KEYS,
	PRODUCTION_RIGGING_KEYS,
	PRODUCTION_POWER_KEYS,
	PRODUCTION_SHOW_KEYS,
	PRODUCTION_HOUSE_KEYS,
	FACILITIES_FACILITY_KEYS,
	FACILITIES_PARKING_KEYS,
	FACILITIES_INFORMATION_TECH_KEYS,
} from "../../util/constants/STAGE_SUBCATEGORY_KEYS"
import { useLocation } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics"
import { useEffect } from "react"
import Navigation from "../organisms/techPack/Navigation/Navigation"
import SectionTitle from "../organisms/techPack/SectionTitle"
import SearchIcon from "@mui/icons-material/Search"
import { ThemeProvider } from "styled-components"
import getTheme from "../../theme"
import useDarkMode from "../../hooks/useDarkMode"

const TechPack = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const analytics = getAnalytics()
	const { venue, personnel, showSection, showSubsection, setShowSearchModal } = useTechPack()
	const theme = useTheme()

	const showPersonnel = !!personnel.length,
		showProduction = showSection("productionData"),
		showProductionStaging = showSubsection("productionData", PRODUCTION_STAGING_KEYS),
		showProductionLoading = showSubsection("productionData", PRODUCTION_LOADING_KEYS),
		showProductionRigging = showSubsection("productionData", PRODUCTION_RIGGING_KEYS),
		showProductionPower = showSubsection("productionData", PRODUCTION_POWER_KEYS),
		showProductionShow = showSubsection("productionData", PRODUCTION_SHOW_KEYS),
		showProductionHouse = showSubsection("productionData", PRODUCTION_HOUSE_KEYS),
		showFacilities = showSection("facilitiesData"),
		showFacilitiesFacility = showSubsection("facilitiesData", FACILITIES_FACILITY_KEYS),
		showFacilitiesParking = showSubsection("facilitiesData", FACILITIES_PARKING_KEYS),
		showFacilitiesInformationTech = showSubsection(
			"facilitiesData",
			FACILITIES_INFORMATION_TECH_KEYS,
		),
		showEquipment = showSection("equipmentData"),
		showLogistics = showSection("logisticsData"),
		showLocalCrew = showSection("localCrewData")

	const scrollToHash = () => {
		const scrollToHashElement = () => {
			const hash = window.location.hash?.replace(/[#/]/g, "")
			const elementToScroll = document.getElementById(hash)

			if (!elementToScroll) return
			setTimeout(
				() =>
					window.scrollTo({
						top: elementToScroll.offsetTop,
						behavior: "smooth",
					}),
				500,
			)
		}

		scrollToHashElement()
		window.addEventListener("hashchange", scrollToHashElement)
		return window.removeEventListener("hashchange", scrollToHashElement)
	}

	useEffect(() => {
		if (venue.name) {
			logEvent(analytics, "page_view", {
				page_title: `Tech Pack Venue Name: ${venue.name}`,
				page_location: window.location.href,
				page_path: location.pathname,
			})
			scrollToHash()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, venue.name])

	return (
		<>
			<Header />
			<SearchContainer>
				<SearchPlaceholder onClick={() => setShowSearchModal(true)}>
					<PlaceholderContainer>
						<SearchIcon sx={{ fontSize: 30, color: theme.techPackText }} />
						<StyledSearchText>{t("search")}</StyledSearchText>
					</PlaceholderContainer>
				</SearchPlaceholder>
			</SearchContainer>
			<Container>
				<Navigation />
				<ContentContainer>
					<GeneralInfo />
					{showPersonnel && <ContactSection />}
					{showProduction && (
						<StyledSectionContainer>
							<SectionTitle sectionName={t("production")} />
							{showProductionStaging && (
								<SectionGroup>
									<StyledTitle id={t("staging")}>{t("staging")}</StyledTitle>
									{PRODUCTION_STAGING_KEYS.map(k => (
										<CreateTechRow
											id={k.charAt(0).toUpperCase() + k.slice(1)}
											key={k}
											field={k}
											category="productionData"
										/>
									))}
								</SectionGroup>
							)}
							{showProductionLoading && (
								<SectionGroup>
									<StyledTitle id={t("loading")}>{t("loading")}</StyledTitle>
									{PRODUCTION_LOADING_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="productionData" />
									))}
								</SectionGroup>
							)}
							{showProductionRigging && (
								<SectionGroup>
									<StyledTitle id={t("rigging")}>{t("rigging")}</StyledTitle>
									{PRODUCTION_RIGGING_KEYS.map(k => (
										<CreateTechRow
											id={k.charAt(0).toUpperCase() + k.slice(1)}
											key={k}
											field={k}
											category="productionData"
										/>
									))}
								</SectionGroup>
							)}
							{showProductionPower && (
								<SectionGroup>
									<StyledTitle id={t("power")}>{t("power")}</StyledTitle>
									{PRODUCTION_POWER_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="productionData" />
									))}
								</SectionGroup>
							)}
							{showProductionShow && (
								<SectionGroup>
									<StyledTitle id={t("show")}>{t("show")}</StyledTitle>
									{PRODUCTION_SHOW_KEYS.map(k => (
										<CreateTechRow
											id={k.charAt(0).toUpperCase() + k.slice(1)}
											key={k}
											field={k}
											category="productionData"
										/>
									))}
								</SectionGroup>
							)}
							{showProductionHouse && (
								<SectionGroup>
									<StyledTitle id={t("house")}>{t("house")}</StyledTitle>
									{PRODUCTION_HOUSE_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="productionData" />
									))}
								</SectionGroup>
							)}
						</StyledSectionContainer>
					)}
					{showFacilities && (
						<StyledSectionContainer>
							<SectionTitle sectionName={t("facility_plural")} />
							{showFacilitiesFacility && (
								<SectionGroup>
									<StyledTitle id={t("facility")}>{t("facility")}</StyledTitle>
									{FACILITIES_FACILITY_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="facilitiesData" />
									))}
								</SectionGroup>
							)}
							{showFacilitiesParking && (
								<SectionGroup>
									<StyledTitle id={t("parking")}>{t("parking")}</StyledTitle>
									{FACILITIES_PARKING_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="facilitiesData" />
									))}
								</SectionGroup>
							)}
							{showFacilitiesInformationTech && (
								<SectionGroup>
									<StyledTitle id={t("informationTech").replace(/ /g, "")}>
										{t("informationTech")}
									</StyledTitle>
									{FACILITIES_INFORMATION_TECH_KEYS.map(k => (
										<CreateTechRow key={k} field={k} category="facilitiesData" />
									))}
								</SectionGroup>
							)}
						</StyledSectionContainer>
					)}
					{showEquipment && (
						<StyledSectionContainer>
							<SectionTitle sectionName={t("equipment")} />
							<CreateTechRow field="audio" category="equipmentData" />
							<CreateTechRow field="audioComments" category="equipmentData" />
							<CreateTechRow field="staging" category="equipmentData" />
							<CreateTechRow field="lighting" category="equipmentData" />
							<CreateTechRow field="lightComments" category="equipmentData" />
							<CreateTechRow field="backline" category="equipmentData" />
							<CreateTechRow field="video" category="equipmentData" />
							<CreateTechRow field="videoComments" category="equipmentData" />
							<CreateTechRow field="other" category="equipmentData" />
						</StyledSectionContainer>
					)}
					{showLogistics && (
						<StyledSectionContainer>
							<SectionTitle sectionName={t("logistics")} />
							<CreateTechRow field="airportNotes" category="logisticsData" />
							<CreateTechRow field="groundTransport" category="logisticsData" />
							<CreateTechRow field="areaBusiness" category="logisticsData" />
							<CreateTechRow field="areaRestaurants" category="logisticsData" />
							<CreateTechRow field="areaHotels" category="logisticsData" />
							<CreateTechRow field="interest" category="logisticsData" />
							<CreateTechRow field="closestCity" category="logisticsData" />
							<CreateTechRow field="directions" category="logisticsData" />
						</StyledSectionContainer>
					)}
					{showLocalCrew && (
						<StyledSectionContainer>
							<SectionTitle sectionName={t("localCrew")} />
							<CreateTechRow field="localUnion" category="localCrewData" />
							<CreateTechRow field="minimumIN" category="localCrewData" />
							<CreateTechRow field="minimumOUT" category="localCrewData" />
							<CreateTechRow field="penalties" category="localCrewData" />
							<CreateTechRow field="crewComments" category="localCrewData" />
						</StyledSectionContainer>
					)}
				</ContentContainer>
			</Container>
		</>
	)
}

const TechPackContainer = () => {
	const { darkMode } = useDarkMode()
	const theme = getTheme(darkMode)
	return (
		<ThemeProvider theme={theme}>
			<TechPackContextProvider>
				<TechPack />
				<TechPackModal />
			</TechPackContextProvider>
		</ThemeProvider>
	)
}

const Container = styled.div`
	background: ${({ theme }) => theme.techPackPrimaryBackground};
	padding-top: 50px;
	padding-right: 50px;
	padding-bottom: 50px;
	padding-left: 50px;
	position: relative;
	min-height: 100%;
	display: flex;
	gap: 50px;
	word-break: break-word;
	color: ${({ theme }) => theme.techPackText};

	${mediaQuery("md")`
		padding-top: 30px;
	`}
	${mediaQuery("sm")`
		padding-right: 15px;
		padding-bottom: 15px;
		padding-left: 15px;
	`}

	@media (prefers-color-scheme: dark) {
		a {
			color: blue;
		}
	}
`
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`
const StyledSectionContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border: ${({ theme }) => `1px solid ${theme.techPackBorder}`};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin-top: 30px;
	width: 100%;
`
const StyledTitle = styled.p`
	font-family: "RobotoCondensed-Bold" !important;
	font-size: 22px;
	margin-top: 30px;
	color: ${({ theme }) => theme.techPackTextHeadings};

	${mediaQuery("md")`
		font-size: 18px;
	`}
`
const SectionGroup = styled.div``
const SearchContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackPrimaryBackground};
	display: none;
	justify-content: center;
	padding-top: 15px;
	padding-right: 50px;
	padding-left: 50px;

	${mediaQuery("md")`
		display: flex;
	`}
	${mediaQuery("sm")`
		padding-right: 15px;
		padding-left: 15px;
	`}
`
const SearchPlaceholder = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
`
const PlaceholderContainer = styled.div`
	display: flex;
	align-items: center;
	height: 80px;
	color: ${({ theme }) => theme.techPackText};
	font-size: 18px;
	padding-left: 10px;
`
const StyledSearchText = styled.p`
	margin-left: 14px;
`

export default TechPackContainer
