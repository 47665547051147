import attachmentDisplayName from "../../../util/attachmentDisplayName"
import useTechPack from "../../../hooks/useTechPack"
import { FC, useEffect, useRef } from "react"
import styled, { useTheme } from "styled-components"
import PdfFile from "../../../icons/PdfFile"
import VideoFile from "../../../icons/VideoFile"
import ImageFile from "../../../icons/ImageFile"
import File from "../../../icons/File"
import LeftChevron from "../../../icons/LeftChevron"
import RightChevron from "../../../icons/RightChevron"

export type TechPackParams = {
	category: keyof Stage
	field: string
}

const ModalAttachmentRow: FC<TechPackParams> = ({ category, field }) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const theme = useTheme()
	const { attachments: stateAttachments, setState, state } = useTechPack()

	const attachments: Attachment[] =
		(stateAttachments[category] && stateAttachments[category][field]) || []

	const getFileIcon = (item: Attachment) => {
		const ext = item.file?.filename?.split(".").pop() || ""
		// use ext to return file icon
		switch (ext) {
			case "pdf":
				return <PdfFile size={24} color={theme.darkGb3} />
			case "jpg":
			case "jpeg":
			case "png":
			case "gif":
				return <ImageFile size={24} color={theme.darkGb3} />
			case "mp4":
			case "mov":
			case "avi":
			case "wmv":
			case "flv":
			case "mkv":
			case "webm":
				return <VideoFile size={24} color={theme.darkGb3} />
			default:
				return <File size={24} color={theme.darkGb3} />
		}
	}

	const handleScroll = (direction: "previous" | "next") => {
		if (containerRef.current) {
			switch (direction) {
				case "previous":
					containerRef.current.scrollBy({
						top: 0,
						left: -100,
						behavior: "smooth",
					})
					break
				case "next":
					containerRef.current.scrollBy({
						top: 0,
						left: +100,
						behavior: "smooth",
					})
					break
			}
		}
	}

	const scrollToSelectedAttachment = () => {
		// will scroll selected attachment into view
		if (containerRef.current) {
			const selectedAttachment = state.selectedAttachment
			const selectedAttachmentIndex = selectedAttachment.selectedIndex
			const selectedAttachmentCategory = selectedAttachment.category
			const selectedAttachmentField = selectedAttachment.field
			const selectedAttachmentCategoryField =
				stateAttachments[selectedAttachmentCategory][selectedAttachmentField]
			const selectedAttachmentCategoryFieldLength = selectedAttachmentCategoryField.length
			const selectedAttachmentCategoryFieldIndex = selectedAttachmentCategoryField.findIndex(
				(item: StageAttachment) =>
					item.fileId === selectedAttachmentCategoryField[selectedAttachmentIndex].fileId,
			)
			const selectedAttachmentCategoryFieldIndexIsFirst = selectedAttachmentCategoryFieldIndex === 0
			const selectedAttachmentCategoryFieldIndexIsLast =
				selectedAttachmentCategoryFieldIndex === selectedAttachmentCategoryFieldLength - 1

			if (selectedAttachmentCategoryFieldIndexIsFirst) {
				containerRef.current.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				})
			} else if (selectedAttachmentCategoryFieldIndexIsLast) {
				containerRef.current.scrollTo({
					top: 0,
					left: containerRef.current.scrollWidth,
					behavior: "smooth",
				})
			} else {
				containerRef.current.scroll({
					top: 0,
					left: selectedAttachmentCategoryFieldIndex * 100 - 25,
					behavior: "smooth",
				})
			}
		}
	}

	useEffect(() => {
		scrollToSelectedAttachment()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedAttachment.selectedIndex])

	return (
		<StyledContainer>
			<LeftArrowContainer onClick={() => handleScroll("previous")}>
				<LeftChevron size={30} color={theme.lightGb1} />
			</LeftArrowContainer>
			<Wrapper ref={containerRef}>
				{attachments
					.sort((a, b) => (a.metadata?.order || 0) - (b.metadata?.order || 0))
					.map((item, index) => {
						const displayName = attachmentDisplayName(item.file, false)
						const previewImage = item.file?.publicUrls?.streaming?.thumbNailsUrls?.length
							? item.file?.publicUrls?.streaming?.thumbNailsUrls[0]
							: item.file?.publicUrls?.urlPreview
						return (
							<StyledContentContainer key={item.id}>
								<StyledRowContainer
									key={`${item.fileId}`}
									title={displayName}
									onClick={() => {
										if (index === state.selectedAttachment.selectedIndex) {
											scrollToSelectedAttachment()
										} else {
											setState((s: TechPackStateInterface) => ({
												...s,
												selectedAttachment: {
													category,
													field,
													selectedIndex: index,
												},
											}))
										}
									}}
								>
									{previewImage ? (
										<ImageContainer>
											<ResponsiveImage src={previewImage} alt={displayName} />
										</ImageContainer>
									) : (
										<StyledIconContainer>{getFileIcon(item)}</StyledIconContainer>
									)}
								</StyledRowContainer>
								<StyledTextContainer>
									<StyledFileText>{attachmentDisplayName(item.file)}</StyledFileText>
								</StyledTextContainer>
							</StyledContentContainer>
						)
					})}
			</Wrapper>
			<RightArrowContainer onClick={() => handleScroll("next")}>
				<RightChevron size={30} color={theme.lightGb1} />
			</RightArrowContainer>
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	position: relative;
`
const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-basis: 100%;
	flex-wrap: nowrap;
	gap: 20px;
	margin-right: 30px;
	margin-left: 30px;
	overflow-y: hidden;
	position: relative;
	width: calc(100% - 110px);
	height: 105px;
	::-webkit-scrollbar {
		width: 0 !important;
	}
	scrollbar-width: none;
`
const StyledContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100px;

	:hover {
		> div > div {
			transform: translateY(-2px);
		}
	}
`
const StyledRowContainer = styled.div`
	cursor: pointer;
	width: 100%;
`

const ResponsiveImage = styled.img<{ width?: string }>`
	height: auto;
	max-height: 100%;
	max-width: ${({ width }) => (width ? width : "75px")};
	width: 100%;
`
const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	height: 75px;
	transition: transform 0.2s ease-out;
`
const StyledIconContainer = styled.div`
	background-color: ${({ theme }) => theme.lightGb2};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 75px;
	width: 75px;
	transition: transform 0.2s ease-out;
`
const LeftArrowContainer = styled.div`
	cursor: pointer;
	margin-left: 55px;
	margin-bottom: 12px;
`
const RightArrowContainer = styled.div`
	cursor: pointer;
	margin-right: 55px;
	margin-bottom: 12px;
`
const StyledTextContainer = styled.div`
	text-align: center;
`
const StyledFileText = styled.p`
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 75px;
	margin: 7px 0px 0px 0px;
`

export default ModalAttachmentRow
