import { FormControlLabel, Checkbox as MUICheckbox } from "@mui/material"
import { ChangeEvent, FC } from "react"
import styled from "styled-components"

type CheckboxTypes = {
	checked: boolean
	label: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<CheckboxTypes> = ({ checked, label, onChange, ...props }) => (
	<StyledCheckbox
		control={<MUICheckbox checked={checked} onChange={onChange} {...props} />}
		label={label}
		labelPlacement="start"
	/>
)

const StyledCheckbox = styled(FormControlLabel)`
	margin-left: 0 !important;
	& svg {
		color: ${({ theme }) => theme.text} !important;
	}
`

export default Checkbox
