import { Dispatch, FC, SetStateAction, useState } from "react"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled, { useTheme } from "styled-components"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import useDarkMode from "../../hooks/useDarkMode"
import useUserContext from "../../hooks/useUserContext"
import UserAvatar from "../atoms/UserAvatar"
import { DialogTitle, ListItemButton, Switch } from "@mui/material"
import mediaQuery from "../../util/mediaQuery"
import { useTranslation } from "react-i18next"
import VenueSelector from "../molecules/VenueSelector"
import useConfirmClose from "../../hooks/useConfirmClose"
import Modal from "../molecules/Modal"
import useVenues from "../../hooks/useVenues"
import useAxios from "../../hooks/useAxios"
import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { AxiosResponse } from "axios"
import useGetVenues from "../../hooks/useVenues"

const ToggleDarkMode = ({ ...rest }) => {
	const { darkMode, setDarkMode } = useDarkMode()

	return (
		<DrawerLinkList style={{ cursor: "pointer" }} onClick={() => setDarkMode(b => !b)} {...rest}>
			<DrawerListItem key="Tech Pack">
				<DrawerListItemText primary={`${darkMode ? "Dark" : "Light"} Mode`} />
				<DrawerIconGrid style={{ paddingRight: 0 }}>
					<Switch checked={darkMode} />
				</DrawerIconGrid>
			</DrawerListItem>
		</DrawerLinkList>
	)
}

export const PreventNavigationModal: FC<{
	closeDrawer?: () => void
	navigationPath: string
	setShowPreventModal: Dispatch<SetStateAction<boolean>>
	showPreventModal: boolean
}> = ({ closeDrawer, navigationPath, setShowPreventModal, showPreventModal }) => {
	const { setShouldPreventClose } = useConfirmClose()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const handleDrawer = () => {
		setShowPreventModal(false)
		closeDrawer && closeDrawer()
	}
	const handleClose = () => {
		handleDrawer()
	}
	const handleSubmit = () => {
		setShouldPreventClose(false)
		navigate(navigationPath)
		handleDrawer()
	}
	return (
		<Modal
			open={showPreventModal}
			onClose={handleClose}
			onSubmit={handleSubmit}
			submitText={t("discard")}
			showClose={false}
		>
			<StyledDialogTitle>{t("discardPrompt")}</StyledDialogTitle>
		</Modal>
	)
}

const NavDrawer: FC = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const theme = useTheme()
	const { userInitials } = useUserContext()
	const { shouldPreventClose } = useConfirmClose()
	const [showPreventModal, setShowPreventModal] = useState(false)
	const [navigationPath, setNavigationPath] = useState("/")
	const { t } = useTranslation()

	const { userCanViewVenues } = useGetVenues()

	const { avatar } = useUserContext()

	const { selectedStage, selectedVenue } = useVenues()

	// Handle side nav opening and closing
	const openDrawer = () => setIsDrawerOpen(true)
	const closeDrawer = () => setIsDrawerOpen(false)

	const NavItem: FC<{
		to?: string | undefined
		text: string
		icon?: IconProp | any
		customIcon?: any
		onClick?: UseMutateFunction<AxiosResponse<any, any>, string, void, unknown> | undefined
	}> = ({ to, text, icon, onClick, customIcon = null, ...rest }) => {
		const navigate = useNavigate()
		const theme = useTheme()
		const isActive = window.location.pathname === to

		const handleNav = () => {
			if (onClick) {
				onClick()
			} else {
				if (shouldPreventClose) {
					// prevent navigation should modal
					setShowPreventModal(true)
					if (to) {
						setNavigationPath(to)
					}
				} else if (!isActive) {
					// ensure button ripple effect
					setTimeout(() => {
						closeDrawer()
						if (to) {
							navigate(to)
						}
					}, 200)
				}
			}
		}

		return (
			<DrawerLink onClick={handleNav} className={isActive ? "active" : ""} {...rest}>
				<DrawerListItemButton>
					<DrawerLinkList>
						<DrawerListItem key={text}>
							<DrawerListItemText primary={text} />
							{customIcon || (
								<DrawerIconGrid>
									<FontAwesomeIcon icon={icon} color={theme.textNavigation} />
								</DrawerIconGrid>
							)}
						</DrawerListItem>
					</DrawerLinkList>
				</DrawerListItemButton>
			</DrawerLink>
		)
	}

	const ProfileNavItem = () =>
		userInitials || avatar ? (
			<NavItem
				to="/profile"
				text="Profile"
				data-cy="navDrawer-option-profile"
				customIcon={
					<UserAvatar
						src={avatar}
						sx={{ bgcolor: theme.primaryButtonColor, marginRight: "4px", marginTop: "4px" }}
					>
						<h6>{userInitials}</h6>
					</UserAvatar>
				}
			/>
		) : (
			<NavItem to="/profile" text="Profile" icon="warning" data-cy="navDrawer-option-profile" />
		)

	// Logout
	const axios = useAxios()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { mutate: handleLogout } = useMutation(
		async () => {
			return axios.get("/venues/v1/logout")
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: () => {
				localStorage.token = ""

				//clear react-query cache
				queryClient.invalidateQueries()

				navigate("/login")
			},
		},
	)

	return (
		<Box>
			<PositionDrawer>
				<DrawerOpenChevron onClick={openDrawer} data-cy="navDrawer-openChevron">
					<FontAwesomeIcon icon="angles-right" />
				</DrawerOpenChevron>
			</PositionDrawer>
			<DrawerContainer
				disableRestoreFocus
				variant="temporary"
				open={isDrawerOpen}
				onClose={closeDrawer}
			>
				<MouseContainer onMouseLeave={closeDrawer}>
					<VenueSelectorContainer>
						<VenueSelector
							textColor={theme.textNavigationSelect}
							selectedColor={theme.textNavigation}
							data-cy="navDrawer_venueSelect"
						/>
					</VenueSelectorContainer>
					<LinksContainer>
						<LinkGroup>
							<NavItem
								to="/"
								text={t("dashboard")}
								icon="house"
								data-cy="navDrawer-option-dashboard"
							/>
						</LinkGroup>

						{userCanViewVenues && (
							<>
								<LinkGroup>
									<NavItem
										to="/staff-crew"
										text={t("staffCrew")}
										icon="people-carry-box"
										data-cy="navDrawer-option-staffCrew"
									/>
									<NavItem
										to="/production"
										text={t("production")}
										icon="stopwatch"
										data-cy="navDrawer-option-production"
									/>
									<NavItem
										to="/venueStage"
										text={t("venueStage")}
										icon="cart-flatbed-suitcase"
										data-cy="navDrawer-option-venueStage"
									/>
									<NavItem to="/history" text={t("history")} icon="history" />
								</LinkGroup>

								<LinkGroup>
									<NavItem
										onClick={() => {
											if (selectedVenue?.id && selectedStage?.id) {
												window.open(`venue/${selectedVenue.id}/${selectedStage.id}`)
											}
										}}
										text={t("techPack")}
										icon="clipboard-list"
										data-cy="navDrawer-option-techPack"
									/>
								</LinkGroup>
							</>
						)}

						<LinkGroup>
							<ProfileNavItem />
						</LinkGroup>

						<BottomContainer>
							<LinkGroup>
								<NavItem
									text={t("logout")}
									icon="sign-out"
									onClick={handleLogout}
									data-cy="navDrawer-option-logout"
								/>
								<ToggleDarkMode data-cy="navDrawer-options-darkModeToggle" />
							</LinkGroup>
						</BottomContainer>
					</LinksContainer>
				</MouseContainer>
			</DrawerContainer>
			{PreventNavigationModal({
				closeDrawer,
				navigationPath,
				setShowPreventModal,
				showPreventModal,
			})}
		</Box>
	)
}

const BottomContainer = styled.div`
	position: absolute;
	bottom: 25px;
	width: 100%;
`

const PositionDrawer = styled.div`
	z-index: 98;
	position: fixed;
	top: 3rem;
	left: 3rem;
	${mediaQuery("md")`
		top: 1rem;
		left: 0;
	`}
`

const DrawerOpenChevron = styled(IconButton)`
	color: ${({ theme }) => theme.primaryButtonColor}!important;
	z-index: 99 !important;
	height: 64px !important;
	width: 64px !important;
	padding: 8px !important;
	&:hover {
		color: ${({ theme }) => theme.primaryButtonHover}!important;
	}
`

const DrawerLink = styled.div`
	color: ${({ theme }) => theme.drawerLinkTextInactive} !important;
	text-decoration: none;
	& span {
		font-size: 16px !important;
		font-weight: 900 !important;
	}
	&.active span {
		color: ${({ theme }) => theme.drawerLinkTextActive} !important;
	}
	&.active svg {
		color: ${({ theme }) => theme.drawerLinkTextActive} !important;
	}
	&.active ul {
		background-color: ${({ theme }) => theme.drawerLinkBackgroundActive}!important;
	}
`

const DrawerListItemButton = styled(ListItemButton)`
	margin: 0 !important;
	padding: 0 !important;
`

const DrawerListItemText = styled(ListItemText)`
	color: ${({ theme }) => theme.textNavigation};
	> span {
		font-weight: bold;
		::selection {
			color: none;
			background: none;
		}
	}
`

const DrawerContainer = styled(Drawer)`
	z-index: 999999;
	& .MuiPaper-root {
		background-color: ${({ theme }) => theme.primaryColor};
		width: 279px;
	}
	& .button-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

const VenueSelectorContainer = styled.div`
	margin: 3rem 1rem 0rem;

	> div > div {
		color: ${({ theme }) => theme.body} !important;
		font-weight: bold;
		margin: -0.5rem 0rem;
	}
`
const LinksContainer = styled(Grid)`
	margin-top: 40px;
`

const DrawerLinkList = styled(List)`
	padding: 0px !important;
	width: 100% !important;
`

const DrawerListItem = styled(ListItem)`
	padding: 0px 8px 4px 16px !important;
`

const DrawerIconGrid = styled(Grid)`
	padding-left: 32px;
	padding-right: 16px;
	display: flex;
	justify-content: flex-end;

	> span > span > span {
		color: ${({ theme }) => theme.background};
	}
`

const LinkGroup = styled(Grid)`
	padding-top: 16px;
	padding-bottom: 16px;
`

const StyledDialogTitle = styled(DialogTitle)`
	color: ${({ theme }) => theme.text} !important;
	text-align: center;
`

const MouseContainer = styled.div`
	height: 100%;
`

export default NavDrawer
