import HoverSvg from "../components/atoms/HoverSvg"

const Facility = ({ color, size }: IconTypes) => {
	const height = size * 1.777778
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.25 4H15.75C16.9688 4 18 5.03125 18 6.25V25.75C18 27.0156 16.9688 28 15.75 28H11.25V24.25C11.25 23.0312 10.2188 22 9 22C7.73438 22 6.75 23.0312 6.75 24.25V28H2.25C0.984375 28 0 27.0156 0 25.75V6.25C0 5.03125 0.984375 4 2.25 4ZM3 15.25V16.75C3 17.1719 3.32812 17.5 3.75 17.5H5.25C5.625 17.5 6 17.1719 6 16.75V15.25C6 14.875 5.625 14.5 5.25 14.5H3.75C3.32812 14.5 3 14.875 3 15.25ZM8.25 14.5C7.82812 14.5 7.5 14.875 7.5 15.25V16.75C7.5 17.1719 7.82812 17.5 8.25 17.5H9.75C10.125 17.5 10.5 17.1719 10.5 16.75V15.25C10.5 14.875 10.125 14.5 9.75 14.5H8.25ZM12 15.25V16.75C12 17.1719 12.3281 17.5 12.75 17.5H14.25C14.625 17.5 15 17.1719 15 16.75V15.25C15 14.875 14.625 14.5 14.25 14.5H12.75C12.3281 14.5 12 14.875 12 15.25ZM3.75 8.5C3.32812 8.5 3 8.875 3 9.25V10.75C3 11.1719 3.32812 11.5 3.75 11.5H5.25C5.625 11.5 6 11.1719 6 10.75V9.25C6 8.875 5.625 8.5 5.25 8.5H3.75ZM7.5 9.25V10.75C7.5 11.1719 7.82812 11.5 8.25 11.5H9.75C10.125 11.5 10.5 11.1719 10.5 10.75V9.25C10.5 8.875 10.125 8.5 9.75 8.5H8.25C7.82812 8.5 7.5 8.875 7.5 9.25ZM12.75 8.5C12.3281 8.5 12 8.875 12 9.25V10.75C12 11.1719 12.3281 11.5 12.75 11.5H14.25C14.625 11.5 15 11.1719 15 10.75V9.25C15 8.875 14.625 8.5 14.25 8.5H12.75Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Facility)
