import styled from "styled-components"
import { TextField, TextFieldProps } from "@mui/material"
import { forwardRef } from "react"

const FormField: React.FC<TextFieldProps> = forwardRef((props, ref) => {
	return (
		<StyledTextField
			{...props}
			variant="standard"
			fullWidth
			InputLabelProps={{
				shrink: true,
			}}
			inputRef={ref}
		/>
	)
})

const StyledTextField = styled(TextField)`
	font-size: ${({ theme }) => theme.fontSize} !important;
	color: ${({ theme }) => theme.text} !important;

	& label {
		color: ${({ theme, error }) => (error ? theme.errorColor : theme.text)} !important;
	}
	& label.Mui-focused {
		color: ${({ theme }) => theme.primaryColor} !important;
	}
	& label.Mui-disabled {
		color: ${({ theme }) => theme.textLight} !important;
	}

	& .MuiInput-underline:after,
	.MuiInput-underline:before {
		border-bottom: 1px solid ${({ theme, error }) => (error ? theme.errorColor : theme.text)} !important;
	}
	& input,
	textarea {
		color: ${({ theme }) => theme.text} !important;
	}
	& input.Mui-disabled,
	textarea.Mui-disabled {
		-webkit-text-fill-color: ${({ theme }) => theme.textLight};
	}
`

export default FormField
