import HoverSvg from "../components/atoms/HoverSvg"

const Equipment = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.0357

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.8125 0.939941C19.5 1.25244 20 2.00244 20 2.75244V26.7524C20 27.5649 19.5 28.2524 18.8125 28.6274C18.0625 28.9399 17.25 28.8149 16.625 28.2524L8.1875 20.7524H4C1.75 20.7524 0 19.0024 0 16.7524V12.7524C0 10.5649 1.75 8.75244 4 8.75244H8.1875L16.625 1.31494C17.25 0.752441 18.0625 0.627441 18.8125 0.939941ZM25.75 10.1274C27.125 11.2524 28 12.8774 28 14.7524C28 16.6899 27.125 18.3149 25.75 19.4399C25.125 19.9399 24.1875 19.8774 23.625 19.1899C23.125 18.5649 23.25 17.6274 23.875 17.1274C24.5625 16.5649 25 15.6899 25 14.7524C25 13.8149 24.5625 13.0024 23.875 12.4399C23.25 11.9399 23.125 11.0024 23.625 10.3149C24.1875 9.68994 25.125 9.62744 25.75 10.1274Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Equipment)
