import Modal from "@mui/material/Modal"
import { useTranslation } from "react-i18next"
import styled, { useTheme } from "styled-components"
import { FC } from "react"
import mediaQuery, { mediaQueryHeight } from "../../../util/mediaQuery"
import useTechPack from "../../../hooks/useTechPack"
import { DEFAULT_ATTACHMENT } from "../../../context/production"
import formatBytes from "../../../util/formatBytes"
import useDownloadAttachment from "../../../hooks/useDownloadAttachment"
import attachmentDisplayName from "../../../util/attachmentDisplayName"
import Xmark from "../../../icons/Xmark"
import Download from "../../../icons/Download"
import { FileDownloadDoneOutlined } from "@mui/icons-material"
import LeftChevron from "../../../icons/LeftChevron"
import RightChevron from "../../../icons/RightChevron"
import ModalAttachmentRow from "./ModalAttachmentRow"
import {
	PRODUCTION_STAGING_KEYS,
	PRODUCTION_LOADING_KEYS,
	PRODUCTION_RIGGING_KEYS,
	PRODUCTION_POWER_KEYS,
	PRODUCTION_SHOW_KEYS,
	PRODUCTION_HOUSE_KEYS,
	FACILITIES_FACILITY_KEYS,
	FACILITIES_PARKING_KEYS,
	FACILITIES_INFORMATION_TECH_KEYS,
} from "../../../util/constants/STAGE_SUBCATEGORY_KEYS"
import ShowPreview from "./ShowPreview"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const ImageModal: FC = () => {
	const { height } = useWindowDimensions()
	const { t } = useTranslation()
	const { downloadAttachment, isDownloadLoading } = useDownloadAttachment()
	const {
		state: {
			selectedAttachment: { category, field, selectedIndex },
		},
		attachments,
		closeSelectedAttachmentModal,
		setState,
	} = useTechPack()

	const open = !!category && !!FileDownloadDoneOutlined
	const theseAttachments: Attachment[] = open ? attachments[category][field] : []
	const thisAttachment = open ? theseAttachments[selectedIndex] : DEFAULT_ATTACHMENT
	const { file } = thisAttachment
	const displayName = attachmentDisplayName(file)

	const handleNextArrow = () => {
		const nextIndex = selectedIndex + 1
		setState((s: TechPackStateInterface) => ({
			...s,
			selectedAttachment: {
				category,
				field,
				selectedIndex: nextIndex >= theseAttachments.length ? 0 : nextIndex,
			},
		}))
	}

	const handlePreviousArrow = () => {
		const previousIndex = selectedIndex - 1
		setState((s: TechPackStateInterface) => ({
			...s,
			selectedAttachment: {
				category,
				field,
				selectedIndex: previousIndex < 0 ? theseAttachments.length - 1 : previousIndex,
			},
		}))
	}
	const getSubCategory = () => {
		// get subcategory from field such as staging, loading, show, facility, parking, etc
		let returnSubCategory = ""
		if (PRODUCTION_STAGING_KEYS.indexOf(field) > -1) {
			returnSubCategory = "staging"
		}
		if (PRODUCTION_LOADING_KEYS.indexOf(field) > -1) {
			returnSubCategory = "loading"
		}
		if (PRODUCTION_RIGGING_KEYS.indexOf(field) > -1) {
			returnSubCategory = "rigging"
		}
		if (PRODUCTION_POWER_KEYS.indexOf(field) > -1) {
			returnSubCategory = "power"
		}
		if (PRODUCTION_SHOW_KEYS.indexOf(field) > -1) {
			returnSubCategory = "show"
		}
		if (PRODUCTION_HOUSE_KEYS.indexOf(field) > -1) {
			returnSubCategory = "house"
		}
		if (FACILITIES_FACILITY_KEYS.indexOf(field) > -1) {
			returnSubCategory = "facility"
		}
		if (FACILITIES_PARKING_KEYS.indexOf(field) > -1) {
			returnSubCategory = "parking"
		}
		if (FACILITIES_INFORMATION_TECH_KEYS.indexOf(field) > -1) {
			returnSubCategory = "informationTech"
		}
		return returnSubCategory
	}
	const subCategory = getSubCategory()
	const theme = useTheme()

	return (
		<Container
			open={open}
			onClose={closeSelectedAttachmentModal}
			onSubmit={closeSelectedAttachmentModal}
			sx={{ zIndex: 99999 }}
		>
			<ModalBox>
				<ModalContent>
					<StyledTitleContainer>
						<StyledTitle>
							<strong>{`${t(category.replace("Data", ""))} ${
								subCategory && `> ${t(subCategory)}`
							} > ${t(field)}`}</strong>
						</StyledTitle>
					</StyledTitleContainer>
					<CloseContainer onClick={closeSelectedAttachmentModal}>
						<Xmark size={36} color={theme.techPackText} />
					</CloseContainer>
					<StyledPreviewContainer>
						<ArrowContainer onClick={handlePreviousArrow}>
							<LeftChevron size={64} color={theme.techPackText} />
						</ArrowContainer>
						<ShowPreview attachment={thisAttachment} />
						<ArrowContainer onClick={handleNextArrow}>
							<RightChevron size={64} color={theme.techPackText} />
						</ArrowContainer>
					</StyledPreviewContainer>
					<BottomContainer>
						<StyledClickContainer
							onClick={() => {
								if (file?.filename.includes(".pdf")) {
									window.open(file.publicUrls?.url || "", "_blank")
								} else if (!isDownloadLoading) {
									downloadAttachment({
										url: file?.publicUrls?.urlDownload || "",
										filename: displayName,
									})
								}
							}}
						>
							<StyledDownloadContainer>
								<Download size={24} color={theme.techPackIcon2} />
							</StyledDownloadContainer>
							<StyledDownloadText>
								{`${t(file?.filename.includes(".pdf") ? "view" : "download")} `}
								<StyledFileName>{displayName}</StyledFileName>
								{` (${formatBytes(file?.filesize || 0)})`}
							</StyledDownloadText>
						</StyledClickContainer>
						{height > 590 ? (
							<ModalAttachmentRow category={category as keyof Stage} field={field} />
						) : null}
					</BottomContainer>
				</ModalContent>
			</ModalBox>
		</Container>
	)
}

const Container = styled(Modal)`
	align-items: center;
	display: flex;
	justify-content: center;
	color: ${({ theme }) => theme.techPackText};

	${mediaQuery("sm")`
		font-size: 12px;
	`}
`

// the md.height style below is a fix for mobile view address bar not adhering to 100vh
const ModalBox = styled.div`
	background-color: ${({ theme }) => theme.techPackModalBackground};
	border: none !important;
	border-radius: 10px;
	height: 90vh;
	padding: 27px 0;
	position: relative;
	outline: none !important;
	overflow: hidden;
	width: 80vw;

	${mediaQuery("md")`
		padding: 27px 0;
		width: 100vw;
		height: calc(100vh - calc(100vh - 100%));
	`}
`
const ModalContent = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
const StyledTitleContainer = styled.div`
	padding: 0px 100px;

	${mediaQuery("md")`
		padding: 0 50px;
	`}
`
const StyledTitle = styled.p`
	font-size: 18px;
`
const CloseContainer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 22px;
	right: 24px;
`
const StyledPreviewContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	margin-top: 15px;
	margin-left: 31px;
	margin-right: 31px;
	height: calc(100% - 250px);

	${mediaQuery("md")`
		margin-left: 15px;
		margin-right: 15px;
	`}
	${mediaQueryHeight("sm")`
		height: 70%;
		margin-bottom: 15px;
	`}
`
const ArrowContainer = styled.div`
	:hover {
		opacity: 0.5;
	}
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;
`
const BottomContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 170px;
	margin-bottom: 30px;
	margin-top: 30px;

	${mediaQueryHeight("sm")`
		height: auto;
		margin-top: 0px;
		margin-bottom: 0px;
	`}
`
const StyledClickContainer = styled.div`
	cursor: pointer;
	display: flex;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	padding: 0 110px;
	width: 100%;

	:hover {
		opacity: 0.8;
	}

	${mediaQuery("md")`
		padding: 0 50px;
	`}
`
// Safari and Firefox have rendering issues with <strong> and <b> tags it seems
const StyledFileName = styled.div`
	margin-left: 4px;
	font-weight: bold;
`
const StyledDownloadText = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 15px;
	word-break: normal;
`
const StyledDownloadContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-top: 4px;
`

export default ImageModal
