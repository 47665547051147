import { FC, ReactNode } from "react"
import styled from "styled-components"
import mediaQuery from "../../util/mediaQuery"

interface KeyValueTypes {
	keyName: string
	value: string | ReactNode
	width?: string
}

const KeyValuePair: FC<KeyValueTypes> = ({ keyName, value, width }) => {
	return (
		<PairContainer>
			<KeyTextContainer width={width}>
				<KeyText>{keyName}</KeyText>
				<Divider />
			</KeyTextContainer>
			<ValueText>{value}</ValueText>
		</PairContainer>
	)
}

const PairContainer = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 1rem;
	font-size: 18px;

	${mediaQuery("md")`
		font-size: 14px;
	`}
`
const KeyTextContainer = styled.div<{ width?: string }>`
	display: flex;
	align-items: center;
	width: ${({ width }) => width};
	margin-right: 15px;

	${mediaQuery("md")`
		margin-right: 8px;
	`}
`
const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.darkGb3};
	margin-left: 15px;
	width: 100%;
`
const KeyText = styled.p`
	width: fit-content;
	white-space: nowrap;
`
const ValueText = styled.div`
	margin-left: 5px;
`

export default KeyValuePair
