import attachmentDisplayName from "../../../util/attachmentDisplayName"
import useTechPack from "../../../hooks/useTechPack"
import { FC } from "react"
import styled from "styled-components"
import PdfFile from "../../../icons/PdfFile"
import VideoFile from "../../../icons/VideoFile"
import ImageFile from "../../../icons/ImageFile"
import File from "../../../icons/File"
import { useTheme } from "styled-components"

export type TechPackParams = {
	category: keyof Stage
	field: string
}

const AttachmentRow: FC<TechPackParams> = ({ category, field }) => {
	const theme = useTheme()
	const { attachments: stateAttachments, setState } = useTechPack()

	const attachments: Attachment[] =
		(stateAttachments[category] && stateAttachments[category][field]) || []

	if (!attachments.length) {
		return null
	}

	const getFileIcon = (item: Attachment) => {
		const ext = item.file?.filename?.split(".").pop() || ""
		// use ext to return file icon
		switch (ext) {
			case "pdf":
				return <PdfFile size={24} color={theme.techPackIcon2} />
			case "jpg":
			case "jpeg":
			case "png":
			case "gif":
				return <ImageFile size={24} color={theme.techPackIcon2} />
			case "mp4":
			case "mov":
			case "avi":
			case "wmv":
			case "flv":
			case "mkv":
			case "webm":
				return <VideoFile size={24} color={theme.techPackIcon2} />
			default:
				return <File size={24} color={theme.techPackIcon2} />
		}
	}

	return (
		<StyledContainer>
			{attachments
				.sort((a, b) => (a.metadata?.order || 0) - (b.metadata?.order || 0))
				.map((item, index) => {
					const displayName = attachmentDisplayName(item.file, false)
					const previewImage = item.file?.publicUrls?.streaming?.thumbNailsUrls?.length
						? item.file?.publicUrls?.streaming?.thumbNailsUrls[0]
						: item.file?.publicUrls?.urlPreview
					return (
						<RowContainer
							key={`${item.fileId}`}
							title={displayName}
							onClick={() => {
								setState((s: TechPackStateInterface) => ({
									...s,
									selectedAttachment: {
										category,
										field,
										selectedIndex: index,
									},
								}))
							}}
						>
							{previewImage ? (
								<ResponsiveImage src={previewImage} alt={displayName} />
							) : (
								<StyledIconContainer>{getFileIcon(item)}</StyledIconContainer>
							)}
						</RowContainer>
					)
				})}
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const RowContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
	margin-top: 0.5rem;
`

const ResponsiveImage = styled.img<{ width?: string }>`
	height: auto;
	max-width: ${({ width }) => (width ? width : "100px")};
	width: 100%;
`

const StyledIconContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackAttachment};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100px;
`

export default AttachmentRow
