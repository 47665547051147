import TextareaAutosize from "@mui/base/TextareaAutosize"
import styled from "styled-components"

export default styled(TextareaAutosize)`
	background-color: ${({ theme }) => theme.background};
	color: ${({ theme }) => theme.text};
	padding: 0.5em !important;
	font-size: ${({ theme }) => theme.fontSize};
	border-radius: 1rem !important;
	box-shadow: ${({ theme }) => theme.innerShadowDesktop}!important;
	resize: none !important;
`
