import HoverSvg from "../components/atoms/HoverSvg"

const Xmark = ({ color, size }: IconTypes) => {
	const height = size / 1.4
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.5 0.5H8.75V5.5C8.75 6.20312 9.29688 6.75 10 6.75H15V18C15 19.4062 13.8672 20.5 12.5 20.5H2.5C1.09375 20.5 0 19.4062 0 18V3C0 1.63281 1.09375 0.5 2.5 0.5ZM10 0.5L15 5.5H10V0.5ZM8.4375 9.5625H8.39844C8.39844 9.05469 8.00781 8.625 7.46094 8.625C6.95312 8.625 6.52344 9.05469 6.52344 9.5625V13.5859L5.3125 12.375C4.96094 11.9844 4.375 11.9844 4.02344 12.375C3.63281 12.7266 3.63281 13.3125 4.02344 13.6641L6.83594 16.4766C7.1875 16.8672 7.77344 16.8672 8.125 16.4766L10.9375 13.6641C11.3281 13.3125 11.3281 12.7266 10.9375 12.375C10.5859 11.9844 10 11.9844 9.64844 12.375L8.4375 13.5859V9.5625Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Xmark)
