import useTechPack from "../../../hooks/useTechPack"
import { FC } from "react"
import styled from "styled-components"
import mediaQuery from "../../../util/mediaQuery"
import AttachmentRow from "./AttachmentRow"
import { useTranslation } from "react-i18next"
import KeyValuePair from "../../atoms/KeyValuePair"

const CreateTechRow: FC<TechPackParams> = ({ category, field, ...rest }) => {
	const { stage, showSubsection } = useTechPack()
	const { t } = useTranslation()

	const stageCategory: any = stage[category] || {}

	let value = stageCategory[field] || ""

	if (!showSubsection(category, [field])) {
		return null
	}

	if (typeof value === "object") {
		value = Object.fromEntries(Object.entries(value).filter(([_, v]) => !!v))
	}

	return (
		<KeyValueContainer id={t(field).replace(/ /g, "")} {...rest}>
			<KeyTextContainer data-cy={`techPack-section_label-${field}`}>
				<Text>{t(field)}</Text>
			</KeyTextContainer>
			<ContentContainer>
				{typeof value === "string" && <ValueText>{value}</ValueText>}
				{typeof value === "object" && (!!value.width || !!value.depth || !!value.height) && (
					<KeyPairContainer>
						{!!value.width && (
							<KeyValuePair
								key={`width-${value.width}`}
								keyName={t("width")}
								value={value.width}
								width="8rem"
							/>
						)}
						{!!value.depth && (
							<KeyValuePair
								key={`depth-${value.depth}`}
								keyName={t("depth")}
								value={value.depth}
								width="8rem"
							/>
						)}
						{!!value.height && (
							<KeyValuePair
								key={`height-${value.height}`}
								keyName={t("height")}
								value={value.height}
								width="8rem"
							/>
						)}
					</KeyPairContainer>
				)}
				{typeof value === "object" && !value.width && !value.depth && !value.height && (
					<KeyPairContainer>
						{Object.keys(value).map((key: string) => (
							<KeyValuePair
								key={`${key}-${value[key]}`}
								keyName={t(key)}
								value={value[key]}
								width="4rem"
							/>
						))}
					</KeyPairContainer>
				)}
				<AttachmentRow category={category} field={field} />
			</ContentContainer>
		</KeyValueContainer>
	)
}

const KeyValueContainer = styled.div`
	margin-top: 30px;
	${mediaQuery("md")`
		margin-top: 15px;
	`}
`
const KeyPairContainer = styled.div``
const KeyTextContainer = styled.div``
const ContentContainer = styled.div``
const Text = styled.p`
	font-family: "RobotoCondensed-Bold" !important;
	font-size: 18px;
	margin-bottom: 15px;
	line-height: 24px;

	${mediaQuery("md")`
		font-size: 14px;
		margin-bottom: 8px;
	`}
`
const ValueText = styled.p`
	white-space: pre-wrap;
	font-size: 18px;
	line-height: 24px;

	${mediaQuery("md")`
		font-size: 14px;
	`}
`

export default CreateTechRow
