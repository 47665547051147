import { FC } from "react"
import styled from "styled-components"
import LinkButton from "../../atoms/LinkButton"
import mediaQuery from "../../../util/mediaQuery"

interface LinkButtonProps {
	sectionName?: string
}

const SectionTitle: FC<LinkButtonProps> = ({ sectionName }) => {
	return (

		<MainContainer id={sectionName}>
			<TitleLabel data-cy={`techPack-sectionTitle_${sectionName}`} id={sectionName?.toLowerCase().replace(/ /g, "")}>{sectionName}</TitleLabel>
			<ButtonContainer >
				<LinkButton
					data-cy={`techPack-copyLink_${sectionName}`}
					textToCopy={`${window.location.origin}${window.location.pathname}#${sectionName
						?.toLowerCase()
						.replace(/ /g, "")}`}
				/>
			</ButtonContainer>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackBackgroundSecondary};
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50px;
	border-bottom: 2px solid ${({ theme }) => theme.techPackTextHeadings};
	color: ${({ theme }) => theme.techPackTextHeadings};
`

const TitleLabel = styled.h1`
	margin: 0;
	font-family: "Roboto-Black";
	${mediaQuery("md")`
		font-size: 22px;
	`}
`

const ButtonContainer = styled.div`
	cursor: pointer;
	margin-bottom: 20px;
`

export default SectionTitle
