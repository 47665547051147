import { useRef, useEffect, useState } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"

import styled, { useTheme } from "styled-components"
import PlayIcon from "../../icons/PlayIcon"
import useWindowDimensions from "../../hooks/useWindowDimensions"

interface VideoTypes {
	file: FileType
}

const VideoPlayer = ({ file }: VideoTypes) => {
	const { darkGb3 } = useTheme()
	const { width } = useWindowDimensions()
	const videoRef = useRef<HTMLVideoElement>(null)
	const playerRef = useRef<typeof videojs.players>(null)
	const [showPlayButton, setShowPlayButton] = useState(true)

	useEffect(() => {
		// Make sure Video.js player is only initialized once
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js")
			const preview = file?.publicUrls?.streaming?.thumbNailsUrls?.length
				? file?.publicUrls?.streaming?.thumbNailsUrls[0]
				: null
			videoElement.classList.add("vjs-big-play-centered")
			if (preview) {
				videoElement.setAttribute("poster", preview)
			}

			if (videoRef.current) {
				videoRef.current.appendChild(videoElement)
				videoRef.current.preload = "auto"
			}

			const player = (playerRef.current = videojs(videoElement, {
				autoplay: false,
				controls: true,
				responsive: true,
				fill: true,
			}))

			player.ready(() => {
				player.src({
					src: file?.publicUrls?.streaming?.url,
					type: "application/x-mpegURL",
				})
			})
		}
		return () => {
			if (playerRef.current && !playerRef.current.isDisposed()) {
				playerRef.current.dispose()
				playerRef.current = null
			}
		}
	}, [file?.publicUrls?.streaming])

	const handlePlay = () => {
		const player = playerRef.current
		if (player) {
			player.play()
		}
	}

	let iconSize = 96
	if (width <= 787) {
		iconSize = 48
	}
	if (width <= 500) {
		iconSize = 32
	}
	if (width <= 370) {
		iconSize = 24
	}
	if (width <= 320) {
		iconSize = 12
	}

	useEffect(() => {
		// add listener to play/pause video
		const player = playerRef.current
		if (player) {
			player.on("pause", () => {
				setShowPlayButton(true)
			})
			player.on("play", () => {
				setShowPlayButton(false)
			})
		}
	}, [playerRef])

	return (
		<StyledVideo data-vjs-player>
			{/* @ts-ignore */}
			<div ref={videoRef} className="container" />
			{showPlayButton ? (
				<StyledIconContainer onClick={handlePlay}>
					<PlayIcon color={darkGb3} size={iconSize} />
				</StyledIconContainer>
			) : null}
		</StyledVideo>
	)
}

const StyledVideo = styled.div`
	position: relative;
	height: 100% !important;
	overflow-y: hidden;

	.container {
		height: 100% !important;
	}

	video-js,
	video,
	.vjs-error-display {
		border-radius: 10px !important;
	}

	.vjs-control-bar {
		background-color: #4a7b85 !important;
		border-bottom-left-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
	}

	.vjs-big-play-button {
		display: none !important;
	}

	.vjs-paused.vjs-has-started .vjs-big-play-button {
		display: block;
	}
	.vjs-tech {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
`

const StyledIconContainer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

export default VideoPlayer
