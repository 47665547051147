import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import LightModeIcon from "@mui/icons-material/Brightness7"
import DarkModeIcon from "@mui/icons-material/Nightlight"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import useDarkMode from "../../../../hooks/useDarkMode"

export const ListDarkModeToggle: React.FC = () => {
	const { t } = useTranslation()
	const { darkMode, setDarkMode } = useDarkMode()

	return (
		<Container>
			<ToggleButtonGroup exclusive aria-label="text alignment">
				<LightToggleButton
					data-cy="darkLightModeToggle_Light"
					value="left"
					aria-label="left aligned"
					$darkmode={darkMode}
					onClick={() => setDarkMode(false)}
				>
					<LightModeIcon />
					<ButtonText>{t("light")}</ButtonText>
				</LightToggleButton>
				<DarkToggleButton
					data-cy="darkLightModeToggle_Dark"
					value="center"
					aria-label="centered"
					$darkmode={darkMode}
					onClick={() => setDarkMode(true)}
				>
					<DarkModeIcon />
					<ButtonText>{t("dark")}</ButtonText>
				</DarkToggleButton>
			</ToggleButtonGroup>
		</Container>
	)
}

const LightToggleButton = styled(ToggleButton)<{ $darkmode: boolean }>(({ theme, $darkmode }) => ({
	"&.MuiToggleButton-root, &.MuiToggleButton-root:hover": {
		fontWeight: 700,
		fontFamily: "Roboto-Regular, sans-serif !important",
		color: $darkmode ? theme.darkGb3 : theme.lightGb3,
		backgroundColor: $darkmode ? theme.lightGb3 : theme.darkGb3,
		border: `solid 1px ${theme.lightGb1}`,
		padding: `5px 20px`,
		flexWrap: 'wrap',
		"@media (max-width: 1023px)" : {
			padding: `5px 10px`,
		}
	},
	"&.MuiToggleButton-root:hover": {
		opacity: 0.75,
	},
}))

const DarkToggleButton = styled(ToggleButton)<{ $darkmode: boolean }>(({ theme, $darkmode }) => ({
	"&.MuiToggleButton-root, &.MuiToggleButton-root:hover": {
		fontWeight: 700,
		fontFamily: "Roboto-Regular, sans-serif !important",
		color: $darkmode ? theme.lightGb3 : theme.darkGb3,
		backgroundColor: $darkmode ? theme.darkGb3 : theme.lightGb3,
		border: `solid 1px ${theme.lightGb1}`,
		padding: `5px 20px`,
		flexWrap: 'wrap',
		"@media (max-width: 1023px)" : {
			padding: `5px 10px`,
		}
	},
	"&.MuiToggleButton-root:hover": {
		opacity: 0.75,
	},
}))

const ButtonText = styled.div`
	margin-left: 6px;
`

const Container = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`

export default ListDarkModeToggle
