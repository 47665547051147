import styled, { useTheme } from "styled-components"
import { default as MuiSelect } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { FC } from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"

type SelectTypes = {
	backgroundColor?: string
	items: { id: string; name: string }[]
	label?: string
	onChange: (value: any) => void
	selected: string | undefined
	selectedColor?: string
	textColor?: string
	title?: string
}

const Select: FC<SelectTypes> = ({
	backgroundColor,
	items,
	onChange,
	selected,
	label,
	selectedColor,
	textColor,
	title,
	...rest
}) => {
	const theme = useTheme()
	return (
		<FormControl fullWidth title={title}>
			{label ? <StyledLabel variant="standard">{label}</StyledLabel> : null}
			<StyledSelect
				{...rest}
				onChange={onChange}
				value={selected}
				label={label}
				variant="standard"
				selectedcolor={selectedColor}
				// Gets rid of UI bug with window scroll bar
				MenuProps={{ disableScrollLock: true }}
				sx={{
					":before": {
						borderBottomColor: selectedColor ? selectedColor : theme.text,
					},
					":after": {
						borderBottomColor: selectedColor ? selectedColor : theme.text,
					},
				}}
			>
				{items.map((option, i) => (
					<StyledMenuItem key={`select_${option.id}_${i}`} value={option.id} textcolor={textColor}>
						{option.name}
					</StyledMenuItem>
				))}
			</StyledSelect>
		</FormControl>
	)
}

const StyledLabel = styled(InputLabel)`
	color: ${({ theme }) => theme.text} !important;
`

const StyledSelect = styled(MuiSelect)<{ selectedcolor?: string }>`
	> div {
		color: ${props => (props.selectedcolor ? props.selectedcolor : props.theme.text)} !important;
	}
	> svg {
		color: ${props => (props.selectedcolor ? props.selectedcolor : props.theme.text)} !important;
	}
`

const StyledMenuItem = styled(MenuItem)<{ textcolor?: string }>`
	background-color: ${props => props.theme.textNavigationSelectBackground} !important;
	color: ${props => (props.textcolor ? props.textcolor : props.theme.text)} !important;
	font-size: 1rem !important;
	max-width: 100%;
`

export default Select
