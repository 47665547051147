import { FC, forwardRef } from "react"
import styled, { useTheme } from "styled-components"
import { LoadingButtonProps } from "@mui/lab"
import useOnlineStatus from "../../hooks/useOnlineStatus"
import { ScaleLoader } from "react-spinners"
import { Button as MuiButton } from "@mui/material"

const Button: FC<LoadingButtonProps> = forwardRef(
	({ disabled, loading, onClick, children, ...rest }, ref) => {
		const isOnline = useOnlineStatus()
		const theme = useTheme()

		// need {...props} for data-cy
		return (
			<StyledButton
				{...rest}
				ref={ref}
				disabled={!isOnline || disabled || loading}
				onClick={e => isOnline && onClick && onClick(e)}
			>
				{loading ? <ScaleLoader color={theme.white} height="1.25rem" /> : children}
			</StyledButton>
		)
	},
)

const StyledButton = styled(MuiButton)`
	border-radius: 20px !important;
	font-size: 16px !important;
	font-weight: 900 !important;
	padding: 0.5rem 1.5rem !important;
	margin-top: 2%;
	margin-bottom: 2%;
	background-color: ${({ theme, color }) =>
		color === "error"
			? theme.errorButtonBackground
			: color === "secondary"
			? theme.secondaryButtonColor
			: theme.primaryButtonColor}!important;
	color: ${({ theme }) => theme.primaryButtonText}!important;

	&:hover {
		background-color: ${({ theme, color }) =>
			color === "error"
				? theme.errorButtonHover
				: color === "secondary"
				? theme.secondaryButtonHover
				: theme.primaryButtonHover}!important;
	}

	&.Mui-disabled {
		opacity: 0.5;
	}
`
export default Button
