import HoverSvg from "../components/atoms/HoverSvg"

const MVIcon = ({ size }: IconTypes) => {
	const height = size * 1.0963
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 703.67 769.4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50.52,616.28c-1-35.35,3.72-67.53,9.67-99.59,3.75-20.24,7.73-40.45,10.65-60.81.71-4.92-1.15-11.16-3.91-15.46C56.42,424.06,45,408.28,34,392.24,23.57,377,11.72,362.55,5.65,344.86-3.55,318-1.06,291.6,9.84,265.59c8.56-20.42,23.35-33.55,43.65-42,21.51-8.93,42.35-19.46,63.62-29,10.62-4.74,21.45-9.1,32.41-13,12.26-4.39,20.69-12.31,26.91-23.77,21-38.61,43.39-76.39,70.2-111.32a233.36,233.36,0,0,1,25-27c11.92-11.27,28.05-13,43-17.17,10.83-3,21.84-2.73,32.68-1.26,16.19,2.18,26.34,13.08,34.57,26.22,14.3,22.83,24.06,47.8,34.6,72.42,5.43,12.68,11.37,25.15,17.53,37.49,3.57,7.15,10.45,10.14,18,11.06,8.23,1,16.53,1.46,24.81,2,22.39,1.46,44.86,2.06,67.15,4.42,33.15,3.52,66.31,7.49,98.54,16.7,17.94,5.13,34.16,12.8,43.29,30.21a250,250,0,0,1,12.66,28.21,80.32,80.32,0,0,1,3,47.1c-2.65,11.33-9,20.92-17.17,28.81-10.53,10.12-21.91,19.36-32.92,29-10.13,8.86-20.31,17.67-30.35,26.63q-33.49,29.9-66.85,60c-6.8,6.13-13.34,12.54-20,18.77-6.39,5.92-8.08,13.17-6.12,21.4q11.82,49.71,23.76,99.39c7.63,31.49,15.6,62.89,23.12,94.41,4.09,17.12,5.46,34.66,2.16,52-3.93,20.54-16.88,35.47-33.66,47.1-24.94,17.29-51.87,18.92-79.88,9-27.65-9.82-52.56-25-77.18-40.54q-53.76-34.05-107.25-68.53c-9.42-6.09-18-6-27.3.17-25.54,16.88-51.07,33.8-77.09,49.91C159.59,713.65,144,722.5,126,725.63c-20.56,3.58-37-2.91-50.45-19-17.2-20.52-24.15-44.36-25-70.48C50.31,628.68,50.52,621.18,50.52,616.28Z"
				fill="#fff"
			/>
			<path
				d="M61.28,620.47c.18-36.75,5.48-73,11.63-109.09q5.1-30,10.79-59.93A35.47,35.47,0,0,0,77.48,423c-8.75-12.16-18.06-23.91-26.9-36C41.46,374.5,32,362.21,23.77,349.12c-25.28-40.38-8-82.26,20.77-105.77,17.4-14.23,37.86-22.86,58.62-30.73,17.43-6.61,34.91-13.18,52.67-18.84,12.18-3.88,20.57-11,27-22q29.19-49.49,59.61-98.25c6.95-11.1,15.59-21.17,23.7-31.52,13.21-16.85,31.3-24.85,52-27.6a84.25,84.25,0,0,1,16.87-.61c15.55,1.1,25.42,9.81,31.76,23.75,15.67,34.42,31.71,68.66,47.6,103,.63,1.36,1.35,2.68,1.88,4.08,4.63,12.18,14.06,16.65,26.43,17.62,20.41,1.59,40.75,4,61.14,5.79,14.58,1.29,29.2,2.18,43.81,3.2a557.66,557.66,0,0,1,90.64,14.16c22.61,5.41,34.06,22.1,42,42,10.71,27,5.37,49.26-16.87,68.9-20.34,18-41.44,35.08-61.77,53.07-28.81,25.5-57.09,51.61-85.88,77.15-9.35,8.29-11.69,17.51-8.68,29.5q24.69,98.29,48.54,196.81a145.1,145.1,0,0,1,4.3,36.49c-.45,21.89-14.46,36.06-31.25,47.86-19.47,13.68-40.49,16.46-61.92,6.14-16.31-7.85-31.86-17.35-47.41-26.69q-70.26-42.2-140.19-84.95c-10.54-6.46-20.05-7-30.71-.4-33.81,21-67.83,41.7-101.84,62.38a131.58,131.58,0,0,1-18.05,9.57c-14.68,6-28.23,3.33-39.88-7.26C71.5,680.34,62,660.89,61.31,637.47,61.14,631.81,61.28,626.14,61.28,620.47Z"
				fill="#414041"
			/>
			<path
				d="M259.88,587.89c-7.84,3.53-16.1,6.33-23.44,10.69-36.8,21.87-73.3,44.23-110,66.21-10.72,6.41-17.75,4.32-24.05-6.57C97,649,94.8,638.74,93.7,628.2c-2.54-24.28.54-48.23,4.84-72,5.9-32.64,12.49-65.15,18.73-97.73.72-3.75,1.11-7.57,1.6-11.36,1.59-12.23-2.33-22.19-9.62-32.37-18-25.15-34.67-51.31-51.83-77.08-2.5-3.74-4.78-7.63-7.29-11.36-10.07-15-10.37-26.91,2.55-46.14,6-8.88,15-14.41,24.41-18.54,22.85-10,46-19.39,69.16-28.74,11.27-4.54,22.66-8.87,34.23-12.55,11.91-3.79,20-11.23,25.43-22.3,18.12-36.72,42.23-69.68,65.26-103.31,6.49-9.48,12.86-19.06,19.71-28.27a42.12,42.12,0,0,1,30.33-17.33c6-.65,11,1.06,14.22,6.24,3,5,5.51,10.25,8.18,15.42C354.54,92,365.74,113,376.26,134.36c6.83,13.88,12.91,28.14,18.86,42.43,5.79,13.9,10.42,16.86,25.51,17.73,27.08,1.56,54.15,3.37,81.15,5.91,26.81,2.52,53.56,5.84,80.28,9.25,14,1.78,28,3.93,41.8,6.94,15.63,3.43,25.9,17.26,26.29,33,.21,8.14-3.8,14.18-9.26,19.16-18.69,17.06-37.64,33.83-56.55,50.66-16.66,14.83-33.47,29.51-50.08,44.41-18,16.14-35.51,32.76-53.75,48.59-10.4,9-13.49,19-10.12,32.42,15.18,60.36,29.91,120.83,44.73,181.27,3.92,16,8,32,11.28,48.09,3.89,19.13-13.77,40.77-32.16,39-7.56-.74-15-4.13-22.18-7.15-34-14.36-66.07-32.49-97.27-52.07-26.51-16.65-52.34-34.36-78.72-51.21-8.35-5.34-17.51-9.42-26.3-14.07-.29-.23-5-2.31-5-2.31"
				fill="#f72630"
			/>
			<g>
				<path
					d="M260.53,587.73c-12.14-4.34-18.22-13.05-18.79-27.15-.21-5.16-.22-10.33-.24-15.49,0-3.9-1.62-6.31-5.4-8-38.82-17.33-58.23-48.92-61.94-89.78-2.12-23.43,0-47.25.25-70.88.05-4.83.17-9.66.41-14.49.11-2.43.55-4.84,1-8.95,3.36,2.81,6,4.42,7.88,6.71,5.17,6.39,6.46,14.11,6.5,22.08.1,17.82-.13,35.65.24,53.47.47,22.32,8.06,42.05,23.21,58.59,25.84,28.22,67.45,30.84,97.8,5.9,23.24-19.09,35.24-44,37.5-74,1.64-21.73,4-43.43,6.69-65.06,1.29-10.35,7.31-18.46,15.69-24.63,1.21-.88,3.41-1.77,4.39-1.24s1.66,2.83,1.58,4.3c-.45,8.14-1.22,16.26-1.8,24.4-1.8,25.23-2.47,50.61-5.59,75.68-4.85,38.86-24.49,69.22-57.45,90.54-5.66,3.66-12.21,6.06-18.56,8.5-3.87,1.49-5.59,3.7-5.51,7.76.13,6.33.11,12.66.07,19-.08,12.14-6.42,20.34-18.07,23.49Z"
					fill="#fefefe"
				/>
				<path
					d="M354.21,283.69c6.78.47,13.68.3,20.29,1.62,5.94,1.18,10.54,5.07,11.57,11.69s-1.82,11.62-7.09,14.86a37.25,37.25,0,0,1-13.21,5.33c-8.2,1.26-16.6,1.2-24.9,1.92-6.82.59-7.14,1.07-7.51,7.88-1.24,23.28-2,46.59-3.84,69.83-1.92,24.6-10.08,47.18-25.27,66.84-8.85,11.45-19.43,20.69-34.51,22.88-15.45,2.24-28.26-3-38.51-14.44-13-14.46-19.4-31.87-22.75-50.81-3.41-19.28-.46-38.37.56-57.53.48-9.14,1.44-18.25,2.22-27.38.37-4.29-1.46-6.24-5.82-5.75-6,.67-11.9,1.44-17.88,1.87-5.32.38-10.16-1.19-12.67-6.23s-1.57-10.11,1.45-14.72a22.35,22.35,0,0,1,14.87-10.14c5.88-1.13,11.79-2.21,17.75-2.77,5.29-.51,7.3-3.07,7.95-8.23,2.93-23.45,5.85-46.92,9.44-70.27,4.24-27.59,16.94-50.74,38.92-68.25,11.3-9,24.21-14,39-12.11,10,1.25,17.79,6.23,23.95,14a54.68,54.68,0,0,1,12.18,35.76c-.43,17.56-2.37,35.08-3.6,52.62-.89,12.58-1.66,25.17-2.48,37.75-.25,3.82,1.53,5.58,5.35,5.35l14.47-.82Z"
					fill="#fefefe"
				/>
			</g>
		</svg>
	)
}

export default HoverSvg(MVIcon)
