import styled from "styled-components"
import Paper from "@mui/material/Paper"
import { useState } from "react"

const Card: any = ({ canHover = false, ...rest }: { canHover?: boolean }) => {
	const [isHovering, setHover] = useState(false)
	return (
		<StyledPaper
			{...rest}
			elevation={canHover && isHovering ? 10 : 3}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			data-cy="Card_Component"
		/>
	)
}

const StyledPaper = styled(Paper)`
	border-radius: 32px!important;
	background-color: ${({ theme }) => theme.cardColor}!important;
	color: ${({ theme }) => theme.text}!important;
	display: 'flex',
	justifyContent: 'left'
`

export default Card
