import { Paper as MUIPaper } from "@mui/material"
import { FC } from "react"
import styled from "styled-components"
import useDarkMode from "../../hooks/useDarkMode"

const Paper: FC = rest => {
	const { darkMode } = useDarkMode()
	return <StyledPaper elevation={darkMode ? 15 : 3} {...rest} />
}

const StyledPaper = styled(MUIPaper)`
	background-color: ${({ theme }) => theme.background} !important;
	color: ${({ theme }) => theme.text};
`

export default Paper
