import HoverSvg from "../components/atoms/HoverSvg"

const Link = ({ color, size }: IconTypes) => {
	const height = size * 0.825
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M36.1875 17.2053L29.1875 24.2053C25.6875 27.7678 19.9375 27.7678 16.4375 24.2053C13.3125 21.0803 12.875 16.2053 15.4375 12.5803L15.5 12.5178C16.1875 11.5803 17.4375 11.3928 18.3125 12.0178C19.1875 12.6428 19.4375 13.8928 18.75 14.8303L18.6875 14.8928C17.25 16.8928 17.5 19.6428 19.25 21.3928C21.1875 23.3928 24.375 23.3928 26.375 21.3928L33.375 14.3928C35.375 12.3928 35.375 9.20532 33.375 7.26782C31.625 5.51782 28.875 5.26782 26.875 6.70532L26.8125 6.76782C25.875 7.45532 24.625 7.20532 24 6.33032C23.375 5.45532 23.5625 4.20532 24.4375 3.51782L24.5625 3.45532C28.1875 0.892822 33.0625 1.33032 36.1875 4.45532C39.75 7.95532 39.75 13.7053 36.1875 17.2053ZM3.75 15.7678L10.75 8.70532C14.3125 5.20532 20 5.20532 23.5625 8.70532C26.6875 11.8303 27.0625 16.7678 24.5 20.3928L24.4375 20.4553C23.8125 21.3928 22.5 21.5803 21.625 20.9553C20.75 20.3303 20.5 19.0803 21.1875 18.1428L21.25 18.0803C22.6875 16.0178 22.4375 13.3303 20.6875 11.5803C18.75 9.58032 15.5625 9.58032 13.5625 11.5803L6.5625 18.5803C4.625 20.5178 4.625 23.7053 6.5625 25.7053C8.3125 27.4553 11.0625 27.7053 13.0625 26.2678L13.125 26.2053C14.0625 25.5178 15.3125 25.7678 15.9375 26.6428C16.5625 27.5178 16.375 28.7678 15.5 29.4553L15.375 29.5178C11.8125 32.0803 6.875 31.6428 3.75 28.5178C0.1875 25.0178 0.1875 19.2678 3.75 15.7678Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Link)
