import { DefaultTheme } from "styled-components"
const getTheme: (arg: boolean) => DefaultTheme = isDarkMode => ({
	background: isDarkMode ? "#161e36" : "#F3F3F3",
	black: "#020607",
	borderColor: isDarkMode ? "#F3F3F3" : "#161e36",
	cardColor: isDarkMode ? "#112e55" : "#FCFCFC",
	darkAccent: "#857144",
	darkGb1: "#1D2F33",
	darkGb2: "#274045",
	darkGb3: "#4A7B85",
	drawerLinkTextInactive: isDarkMode ? "#FCFCFC" : "#FCFCFC",
	drawerLinkTextActive: isDarkMode ? "#FCFCFC" : "#201F30",
	drawerLinkBackgroundActive: isDarkMode ? "#201F30" : "#FCFCFC",
	errorColor: "red",
	errorBackground: "#DEE",
	errorButtonBackground: isDarkMode ? "#E33" : "#E55",
	errorButtonHover: isDarkMode ? "#E44" : "#E77",
	fontSize: "1rem",
	interactiveBorderColor: "#4285F4",
	lightAccent: "#D1AF60",
	lightAccentAlt: "#FFDD8F",
	lightAccent20: "rgba(209, 175, 96, 0.2)",
	lightGb1: "#A1B6C2",
	lightGb2: "#D5DFE6",
	lightGb3: "#EDF8FF",
	linkColor: isDarkMode ? "#4285F4" : "#4285F4",
	primaryColor: isDarkMode ? "#4285F4" : "#4285F4",
	primaryButtonColor: isDarkMode ? "#4285F4" : "#4285F4",
	primaryButtonText: isDarkMode ? "#FAFAFA" : "#FAFAFA",
	primaryButtonHover: isDarkMode ? "#6CA1F9" : "#6CA1F9",
	secondaryButtonColor: "#95B4F2",
	secondaryButtonBorderColor: "#AAC",
	secondaryButtonHover: "#BBC",
	secondaryText: isDarkMode ? "#9ABEF9" : "#1A52AD",
	shrunkLabelFontSize: "12px",
	tertiaryBackgroundColor: "#165186",
	tertiaryTextColor: "#FCFCFC",
	text: isDarkMode ? "#FCFCFC" : "#0A0A0A",
	textNavigation: isDarkMode ? "#FCFCFC" : "#FCFCFC",
	textNavigationSelect: isDarkMode ? "#FCFCFC" : "#0A0A0A",
	textNavigationSelectBackground: isDarkMode ? "#201F30" : "#FCFCFC",
	textLight: isDarkMode ? "#777777" : "#B5B5B5",
	timeStampColor: isDarkMode ? "#F8A12F" : "#5E3603",

	fileIconColor: isDarkMode ? "" : "rgba(0,0,0,0.3)",

	white: "#FFF",

	//Text sizing
	pTextSize: "12px",

	//tech pack styles
	techPackPrimaryBackground: isDarkMode ? "#1D2F33" : "#D5DFE6",
	techPackSecondaryBackground: isDarkMode ? "#4A7B85" : "#FFFFFF",
	techPackBorder: isDarkMode ? "#1D2F33" : "#A1B6C2",
	techPackIcon: isDarkMode ? "#D5DFE6" : "#A1B6C2",
	techPackTextHeadings: isDarkMode ? "#FFFFFF" : "#1D2F33",
	techPackText: isDarkMode ? "#EDF8FF" : "#274045",
	techPackAttachment: isDarkMode ? "#274045" : "#D5DFE6",
	techPackIcon2: isDarkMode ? "#D5DFE6" : "#4A7B85",
	techPackModalBackground: isDarkMode ? "#1D2F33" : "#FFFFFF",
	techPackModalPreviewIcon: isDarkMode ? "#274045" : "#4A7B85",
	techPackHighlight: isDarkMode ? "#4A7B85" : "#EDF8FF",
	techPackAccent: isDarkMode ? "#857144" : "rgba(209, 175, 96, 0.2)",
})

export default getTheme
