import { FileIcon as FIcon } from "react-file-icon"
import { useTheme } from "styled-components"

export default function FileIcon({ filename }: { filename: string }) {
	const theme = useTheme()

	const extension = filename.split(".").pop() || ""

	//extension.slice is a hotfix for text overflow in svg
	return <FIcon extension={extension.slice(0, 9)} fold={false} gradientColor={theme.darkGb1} />
}
