import styled from "styled-components"
import { Personnel } from "../../../context/personnel"
import useTechPack from "../../../hooks/useTechPack"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import mediaQuery, { breakpoints } from "../../../util/mediaQuery"
import AvatarImage from "../../molecules/AvatarImage"
import { orderBy } from "lodash"

export default function Contacts() {
	const { width } = useWindowDimensions()
	const { personnel } = useTechPack()
	const imageSize = width < breakpoints.lg ? 60 : 100
	const sortedPersonnel = personnel
		.sort((a, b) => (a.metadata.order || 0) - (b.metadata.order || 0))
		.map(person => {
			const urls = orderBy(person.fileAttachments, [item => item.file?.createdDate], ["desc"])

			return {
				...person,
				imageUrl: urls.length ? urls[0].file.publicUrls.url : undefined,
			}
		})

	return (
		<>
			{sortedPersonnel.map((person: Personnel & { fileAttachments?: AttachmentFromParent[] }) => {
				const {
					id,
					role,
					person: {
						phone,
						email,
						name: { displayName },
					},
				} = person

				return (
					<ContactSectionContainer key={id} id={displayName.replace(/ /g, "")}>
						<LeftContactContainer>
							<AvatarImage staff={person} height={imageSize} width={imageSize} techPack={true} />
						</LeftContactContainer>
						<RightContactContainer>
							<ContactTitle>
								<strong>{role}</strong>
							</ContactTitle>
							<ContactName>{displayName}</ContactName>
							{!!phone && (
								<>
									<a href={`tel:${phone}`}>{phone}</a>
									<br />
								</>
							)}
							{!!email && <a href={`mailto:${email}`}>{email}</a>}
						</RightContactContainer>
					</ContactSectionContainer>
				)
			})}
		</>
	)
}

const ContactSectionContainer = styled.div`
	align-items: center;
	display: flex;
	margin-top: 30px;
	width: 50%;

	${mediaQuery("lg")`
		width: 100%;
	`}
`
const LeftContactContainer = styled.div`
	align-items: center;
	display: flex;
	margin-right: 2rem;
	height: 100px;
	width: 100px;

	${mediaQuery("lg")`
		height: 60px;
		width: 60px;
		margin-right: 1rem;
	`}
`
const RightContactContainer = styled.div`
	font-size: 18px;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-word;
	width: 100%;

	> a {
		color: ${({ theme }) => theme.techPackText} !important;
	}

	${mediaQuery("lg")`
		font-size: 13px;
	`}
`
const ContactTitle = styled.p`
	margin: 0;

	${mediaQuery("lg")`
		font-size: 15px;
	`}
`
const ContactName = styled.p`
	margin: 0;
`
